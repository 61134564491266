/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
.content-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  border-radius: 0.25rem; }
  .content-card .content-card-figure,
  .content-card .content-card-body {
    width: 100%; }
    .content-card .content-card-figure.first,
    .content-card .content-card-body.first {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
    .content-card .content-card-figure.last,
    .content-card .content-card-body.last {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2; }
  .content-card .content-card-image {
    border-radius: 0.25rem; }
  .content-card .content-card-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    color: var(--skin-main-text-color);
    text-decoration: none; }
    .content-card .content-card-link:hover {
      text-decoration: none; }
  .content-card .content-card-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .content-card.content-card--left,
  .content-card .card-align--left {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    text-align: left; }
    .content-card.content-card--left .content-card-text,
    .content-card .card-align--left .content-card-text {
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
  .content-card.content-card--right,
  .content-card .card-align--right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-align: right; }
    .content-card.content-card--right .content-card-text,
    .content-card .card-align--right .content-card-text {
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end; }
  .content-card.content-card--center,
  .content-card .card-align--center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center; }
    .content-card.content-card--center .content-card-text,
    .content-card .card-align--center .content-card-text {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
  .content-card .image-align--left {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .content-card .image-align--right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .content-card .image-align--center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

body.bearcave .content-card .content-card-link {
  color: var(--color-midnight); }

body.bearcave .content-card .content-card-description {
  color: var(--color-midnight); }

body.bearcave.dark-theme .content-card .content-card-link,
body.bearcave .dark-theme .content-card .content-card-link,
body.bearcave .dark-bg .content-card .content-card-link {
  color: var(--color-white); }

body.bearcave.dark-theme .content-card .content-card-description,
body.bearcave .dark-theme .content-card .content-card-description,
body.bearcave .dark-bg .content-card .content-card-description {
  color: var(--color-white); }
